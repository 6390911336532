:root {
  --background-color: #313131;
  --text-color: #fff;
  --text-color-reverse: #313131;
  --primary-color: #ff4c4c;
  --button-color: #ff4c4c;
  --normal-color: #fff;
  --failed-color: #ff4c4c;
  --correct-color: #37be75;
  --input-text-color: #fff;
  --input-placeholder-color: #b4b4b4;
  --table-grey: #878787;
  --table-row-mobile: linear-gradient(0deg, #5f5f5f 0%, #313131);
  --table-row-mobile-correct: linear-gradient(
    0deg,
    #313131 0%,
    #313131 15%,
    #37be75
  );
  --table-row-mobile-failed: linear-gradient(
    0deg,
    #313131 0%,
    #313131 15%,
    #920000
  );
}

* {
  box-sizing: border-box;
}

.App {
  background-color: #151723;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: Inter;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
